<template>
  <div id="marketing" style="height: 100%;" v-title data-title="营销活动">
    <template>
      <div class="marketingTitle flex align-center marketingLine">
        <span></span>
        <span>AuteWiFi营销活动</span>
      </div>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div class="container" style="margin-bottom:1rem;">
          <template v-if="!noData">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="我是有底线的..."
                :offset="130">
              <div class="marketingContentList marketingLine flex align-center" style="border-top: 1px solid #f1f2f4;" @click="jumpDetails(item)" v-for="(item, index) in contentList" :key="index">
                <span></span>
                <span>{{item.name}}</span>
              </div>
            </van-list>
          </template>
          <!--缺省页面-->
          <template v-if="noData">
            <div class="defalut-page">
              <img src="@/assets/img/noRanks.png" alt="">
              <div>暂时数据</div>
            </div>
          </template>
        </div>
      </van-pull-refresh>
    </template>
  </div>
</template>

<script>

export default {
  name: 'PageOrder',
  components: {
  },
  props: {
  },
  data() {
    return {
      page: 1,
      loading: true, // 当loading为true时，转圈圈
      finished: true, // 数据是否请求结束，结束会先显示- 没有更多了 -
      noData: false, // 如果没有数据，显示暂无数据
      isLoading: false, // 下拉的加载图案
      // 订单列表
      contentList: []
    };
  },
  mounted() {
  },
  created() {
    this.getData();
  },
  methods: {
    // // 列表加载
    onLoad () {
      setTimeout(() => {
        this.getData();
        this.loading = true;
      }, 500);
    },
    // // 下拉刷新
    onRefresh() {
      // 重新初始化这些属性
      this.isLoading = false;
      this.contentList = [];
      this.page = 1;
      this.loading = true;
      this.finished = true;
      this.noData = false;
      // 请求信息
      this.getData();
    },
    /* 获取数据 */
    getData() {
      let url = '/mobile/yxform/campaign';
      this.axios.get(url)
          .then(res => {
            if (res.data.code === 0) {
              this.loading = true;
              let list = res.data.data;
              this.contentList = list;
              if (this.contentList.length === 0) {
                this.noData = true;
              } else {
                this.noData = false;
              }
            } else if (res.data.code === 2) { // 绑定微信手机号
              this.bindShow = true;
            } else {
              this.loading = true;
              this.$toast.fail(res.data.msg);
            }
          });
    },
    /* 跳转详情 */
    jumpDetails(row) {
      // localStorage.setItem("acti", row.code);
      this.$router.push({
        name: 'marketing', query: {
          acti: row.code
        },
      })
    }
  }
};
</script>
<style scoped lang="scss">
.marketingTitle {
  font-size:.32rem;color: #3e3e59;
  :first-child{width: 2px;background-color: #2bb8fe;height: .30rem;border-radius: .30rem;margin-right:8px;}
}
.marketingContentList{
  font-size: .28rem;color: #7b7b8d;
  :first-child{width: 5px;background-color: #2bb8fe;height: 5px;border-radius: 50%;margin-right:8px;margin-left:10px;}
}
.marketingLine{background: #fff;height: .8rem;padding: 0 10px;}
</style>
<style>
</style>
